/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import {
  FieldPath,
  collection, getCountFromServer, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import { AuthContext, UserDataContext } from '../../App';
import FirestoreMeetings from '../../database/Meetings/FirestoreMeetings';
import TagsAPI from '../../database/Tags/TagsAPI';
import UserAPI from '../../database/User/UserAPI';
import IntercomCF from '../../external/IntercomCF/IntercomCF';
import SlackBoltAPI from '../../external/SlackBolt/SlackBoltAPI';
import StripeAPI from '../../external/Stripe/StripeAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import SDate from '../../shared/classes/SDate';
import TestClass from '../../shared/classes/TestClass';
import UserId from '../../shared/classes/UserID';
import { gray4 } from '../../shared/colors/COLORS';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { MeetingsObject } from '../../shared/types/types';
import { RESOLVED } from '../../utils/enums';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { toastInfo } from '../../utils/notifications';
// import Stopwatch from '../../utils/Stopwatch/Stopwatch';
import useCounterStore from '../../zustand/useCounterStore';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import { COLLECTIONS, MEETING_PATH, getNestedKeyObject } from '../../database/FirebaseConstants';
import mapGapiResultToGapiMeetingData from '../../database/utils/gapiMeetingDataUtils';
import WorkspaceAPI from '../../database/Workspaces/WorkspaceAPI';
import EmailAPI from '../../external/Email/EmailAPI';
import EmailCoreAPI from '../../external/Email/EmailCoreAPI';
import CloudFunctions from '../../database/CloudFunctions';
import { firestore } from '../../utils/firebase';
import mapDatabaseMeetingDataToMeetingData from '../../database/utils/mapMeetingData';
import GapiMeetings from '../../shared/classes/GapiMeetings';
import { getAnalyticsDataByMeetingId } from '../../database/firebaseAnalyticsAPI';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const HaraldTestingPage = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const [json, setJson] = useState({});
  const [meetings, setMeetings] = useState<MeetingsObject>({});
  const [domain, setDomain] = useState<string>('');

  const count = useCounterStore((state) => state.count);
  const incrementCount = useCounterStore((state) => state.increment);

  useEffect(() => {
    // if (userData.resolvedState !== RESOLVED) return;
    // FirestoreMeetings.listenToMeetingsWhereImInvitedTo(userData.userId,
    //   userData.data.email, setMeetings);
    // FirestoreMeetings.paginateMeetingsByCalendarId('harald@meetshepherd.com');
    // FirestoreMeetings.listenToMeetingsWhereImInvitedTo(
    //   userData.userId, userData.data.email, setMeetings,
    // );
    // FirestoreMeetings.listenToMyTag();
    // FirestoreUserAPI.updateUserWithHaraldTag(authData.userId, 'haraldTag');
    // TagsAPI.addTagToMeeting('0IaYjQpLo1TZokZPAXMD', 'Wufas2u8Olo1DRnGafjm', userData.userId);
    // TagsAPI.listenToMyTags(userData.userId);
    // TagsAPI.addNewTagToMeeting('Some random name',
    // '0IaYjQpLo1TZokZPAXMD', userData.userId, userData.data.email);
  }, [userData.resolvedState]);

  // useEffect(() => {
  //   if (meetings?.keys?.length === 0) return;
  //   console.log('Meetings');
  //   console.log(meetings);
  // }, [meetings]);

  // useEffect(() => {
  //   // const userId = new UserId('haraldlons@gmail.com');
  //   const userId = new UserId('');
  //   // userId.consoleLog();
  //   console.log(userId);
  //   console.log(`UserId: ${userId}`);
  //   if (userId) {
  //     console.log('true in userId');
  //   } else {
  //     console.log('false in userId');
  //   }
  // }, []);
  const dataEventIds = [
    'MjZiODRwMjQzcHN1b2hqcTc1ajdrc24xODZfMjAyMzExMjBUMDcw',
    'NjhvamdjcGpjOHBqMGI5bmNnc2ppYjlrNjBxamdiYjI2OHJqOGI5',
    'XzZkMjM0ZDFtNmdzNGNiOW42bDE0OGI5azYwczM2YjlwNzRzamFi',
    'YzloamdkaHBja3AzMGI5bWNjbzM0YjlrY2tyM2ViYjI3MWozaWI5',
    'Y2w2b2U1cW91Z282Mmk3bDkzOWdmaDAzb2VfMjAyMzExMjRUMDcw',
    'NnNzbTRwMWhjNHMzY2I5bGM5aWowYjlrY2dzM2NiYjE3NHAzZWI5',
    'NjlpbWNvcGc2NWg2Y2JiMTZvcjNjYjlrNnRoNjRiOW82MWltY2I5',
    'Y2NyM2NvaG02NHNqOGI5cDZzcm1jYjlrNjRxajZiYjJjOWlqOGI5',
  ];

  const handleClick1 = async () => {
    toastInfo('Clicked', 'Button 1 clicked');

    // const coll = collection(db, "cities");
    const coll = collection(firestore, COLLECTIONS.MEETINGS);
    // const col = db.collection('meetings')
    const snapshot = await getCountFromServer(coll);
    console.log('count: ', snapshot.data().count);

    // EventId research

    // const gapiMeetings = await GapiMeetings.getMeetingsPreviousWeekAndNextWeek();
    // console.log('GapiMeetings', gapiMeetings);

    // EventId Stop

    // DataEventIds

    // const data = [{
    // //   value: 'hei',
    // //   date: 'test',
    //   date: 'Mon Nov 27 2023',
    //   value: 'MjZiODRwMjQzcHN1b2hqcTc1ajdrc24xODZfMjAyMzExMjdUMDcw',
    // }];

    const data = [{
      date: 'Mon Dec 04 2023',
      value: 'NDljczhhb2lwaDNwdXRhNzZyc29ncmtuNGtfMjAyMzEyMDRUMDEw',
    }, {
      date: 'Mon Dec 04 2023',
      value: 'appt_2r7lfscvvdg6evf8p2u7tmc2e4_20231204T100000/2023',
    },
    {
      date: 'Mon Dec 04 2023',
      value: 'N2lxYW92OWpqMHFhZHF2dXVucGpqNjExZnNfMjAyMzEyMDRUMDQw',
    }, {
      date: 'Mon Dec 04 2023',
      value: 'NDhsZWpiZXU1YWg2czFncDExbWdkZzZldm5fMjAyMzEyMDRUMDUz',
    }, {
      date: 'Mon Dec 04 2023',
      value: 'MWF1NDNhNmIwNXNoMHJrZm5jZG5xcmhnMDZfMjAyMzEyMDRUMTAz',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'MWNwNjV2Y2RibW10YjRqMzhvNmxuMG10dGhfMjAyMzEyMDRUMjMz',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'Nzh1N3V1Ym91NWtucTlrZnRnbWp2bXRjczYgbWd1aW50b0BtdWx0',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'NDljczhhb2lwaDNwdXRhNzZyc29ncmtuNGtfMjAyMzEyMDVUMDEw',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'appt_2r7lfscvvdg6evf8p2u7tmc2e4_20231205T100000/2023',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'Mmd0N3Fqbzd1ams2Z2VxbHM1dnAwaXVrbjEgbWd1aW50b0BtdWx0',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'N2lxYW92OWpqMHFhZHF2dXVucGpqNjExZnNfMjAyMzEyMDVUMDQw',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'MW9haHZuaWJ1aWdraDJyZmNsZHJxcmNqcnAgbWd1aW50b0BtdWx0',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'MmI3ZWRmNTN1Mms5bTB1a3BzdWdoMjZhaTJfMjAyMzEyMDVUMDcw',
    }, {
      date: 'Tue Dec 05 2023',
      value: 'MWF1NDNhNmIwNXNoMHJrZm5jZG5xcmhnMDZfMjAyMzEyMDVUMTAz',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'dWhlNWNoc2tqbWptZzZva283b3F1czQ3aWhfMjAyMzEyMDVUMjMw',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'am0zaDAwYmJ1MGk3M2ExNGdpNjRqMTJhcXVfMjAyMzEyMDZUMDAw',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'NDljczhhb2lwaDNwdXRhNzZyc29ncmtuNGtfMjAyMzEyMDZUMDEw',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'appt_2r7lfscvvdg6evf8p2u7tmc2e4_20231206T100000/2023',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'N2lxYW92OWpqMHFhZHF2dXVucGpqNjExZnNfMjAyMzEyMDZUMDQw',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'NGNvcml0MHVzcXZmMTJxMmJzN2xnMnRuN2kgbWd1aW50b0BtdWx0',
    }, {
      date: 'Wed Dec 06 2023',
      value: 'MWF1NDNhNmIwNXNoMHJrZm5jZG5xcmhnMDZfMjAyMzEyMDZUMTAz',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'NjgzbmV0ZGcyajE4MDE3dm8zcmZwMXRhajIgbWd1aW50b0BtdWx0',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'NTUzbDBnZnZvNDJ0cGpjbHJmaWppdTAxM3EgbWd1aW50b0BtdWx0',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'appt_2r7lfscvvdg6evf8p2u7tmc2e4_20231207T100000/2023',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'MjVpcGMxdmhhNjlpb2o3aTB2OHVlc2kzbDcgbWd1aW50b0BtdWx0',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'N2lxYW92OWpqMHFhZHF2dXVucGpqNjExZnNfMjAyMzEyMDdUMDQw',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'aDBoOWZyZDFtbnBjbHQybGZwc3BlOG84MDNfMjAyMzExMjRUMDYw',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'M3I1Y2l0Yzd0cWRxMGlmNzA3ODV0Mm5odWEgbWd1aW50b0BtdWx0',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'NDljczhhb2lwaDNwdXRhNzZyc29ncmtuNGtfMjAyMzEyMDdUMDEw',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'OW8zOWRwdHNtbDIyc200OXU2MTIxcmdtaTBfMjAyMzEyMDdUMTAw',
    }, {
      date: 'Thu Dec 07 2023',
      value: 'MWF1NDNhNmIwNXNoMHJrZm5jZG5xcmhnMDZfMjAyMzEyMDdUMTAz',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'dWhlNWNoc2tqbWptZzZva283b3F1czQ3aWhfMjAyMzEyMDdUMjMw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'NXZuZjc4ODQ0aW52MWM0N2JoN3M4bGtqNGxfMjAyMzEyMDdUMjMw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'Nmw1NjNpNDllbTlzdnBrc3ExbGsxbWNyY2YgbWd1aW50b0BtdWx0',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'MTk4ODBnamw1YmgyODZtMWllMnVvbmpjbGFfMjAyMzEyMDhUMDAx',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'NDNodDcydm44bjRtazNobWM2NHJncnBvNGZfMjAyMzEyMDhUMDEw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'NDljczhhb2lwaDNwdXRhNzZyc29ncmtuNGtfMjAyMzEyMDhUMDEw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'appt_2r7lfscvvdg6evf8p2u7tmc2e4_20231208T100000/2023',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'N2lxYW92OWpqMHFhZHF2dXVucGpqNjExZnNfMjAyMzEyMDhUMDQw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'MGlnOG5raThnMHJxNGswazloMzVhMGFsaWwgbWd1aW50b0BtdWx0',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'M29mOGI5MWpqcDd0Nzl2bWZicTlka2c4MjVfMjAyMzEyMDhUMDgz',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'MGJsODE1bTY0dGw1cjE3Y2thYXBza29sY2tfMjAyMzEyMDhUMDgz',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'MWF1NDNhNmIwNXNoMHJrZm5jZG5xcmhnMDZfMjAyMzEyMDhUMTAz',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'N2kwN2NiNGUzcTRkbzlmb3VjNWI0NTJkZHUgbWd1aW50b0BtdWx0',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'NjJpamdqOWlrMDcxamNvdTk1dTU2Zm4wZTNfMjAyMzEyMDhUMTMw',
    }, {
      date: 'Fri Dec 08 2023',
      value: 'NGw4dTZ0djh1NzZ1MXMwaGFpc3Y4bmVvcjFfMjAyMzEyMDhUMTUw',
    }, {
      date: 'Sat Dec 09 2023',
      value: 'NGw4dTZ0djh1NzZ1MXMwaGFpc3Y4bmVvcjFfMjAyMzEyMDhUMTUw',
    }];

    // const sw = Stopwatch.start();
    // const result = await CloudFunctions().getMeetingsByDataEventIdsV3({ dataEventIds: data });
    // sw.stopAndPrintElapsedTime();
    // console.log('Result when clicked one, ', result);

    // DataEventIds Stopp

    // const result = await CloudFunctions().addTwoNumbers({ a: 2, b: 3 });
    // console.log('Result when clicked one, ', result);
    // const result = await CloudFunctions().searchPublicUserDataV2ByEmailsV2({ emails: ['haraldlons@gmail.com', ''] });
    // console.log('Result when clicked one, ', result);

    // Test code for inviting someone to a workspace
    // const workspaceName = 'Shepherd';
    // const senderName = 'Harald Lønsethagen';
    // const senderEmail = 'harald@meetshepherd.com';
    // const recipientEmail = 'haraldlons@gmail.com';
    // const recipientName = 'Nadia Chaudry';
    // const result = await EmailAPI.sendWorkspaceInvite(
    //   recipientEmail, recipientName, workspaceName, senderName, senderEmail,
    // );

    // const result = await EmailCoreAPI.cfSendWelcomeEmailCore('Harald');

    // console.log('Result', result);

    // // const userId = new UserId('qhC2OKtyISfpyKAAsgCbrHEEbRH3');
    // const { email } = userData;
    // const add = await WorkspaceAPI
    //   .inviteMemberToWorkspace(userData.workspaces.selectedWorkspaceId,
    //     userData.workspace.name, email, userData.email);

    // const result = await UserAPI.updateStripeCustomerIdToSetTransactiontCore(userData);
    // const result2 = await UserAPI.updateStripeCustomerIdToSetTransactiontCore(userData);
    // console.log('Result', { result, result2, result2IsRejected: result2.isRejected });

    // const emails : string[] = ['harald@meetshepherd.com', 'haraldlons@gmail.com'];
    // const subject = 'Halla på deg';
    // const body = 'Hei, har du noe å gjøre?';
    // EmailAPI.sendBasicEmail(emails, subject, body);
    // SlackAPI.sendNotification('Some title', 'This is the message', userData.publicUserData);

    // const blacklistedDomains = ['gmail.com', 'googlemail.com', 'localhost:3000'];
    // CEComs.removeBlacklistedDomains(blacklistedDomains);
    // TestClass.testMethod();

    // const dataEventIds = [
    //   'MWVlNTVuZjl0djJibTdkN2RobGY5M3NzODggaGFyYWxkQG1lZXRz',
    //   'NDZnbXVkM3Nwc3F1bmg2NDJyYnBwYTZuaTIgaGFyYWxkQG1lZXRz',
    //   'cnUzMjM1OGtwM3ZicmtoM2Jmdjlqcm5kMTQgaGFyYWxkQG1lZXRz',
    //   'Z2VoMjZ0YXA4NXZtdG05bXNiZGtrMTVwZ3ZfMjAyMzAzMTNUMDgw',
    //   'anYwczF0ZWVxcXYzbzNnNnFybG5xNDdnaGMgaGFyYWxkQG1lZXRz',
    //   'MWxoNWwzaWpxMzA4NGtoNXZzOGIwOWoxNXEgaGFyYWxkQG1lZXRz',
    //   'MmdnaWM2dGdicmtqaW45bmlmMmM0MzM2dTIgaGFyYWxkQG1lZXRz',
    //   'MXZuaGJzY2syYWVvZ2Q3Z2Z2dTlqcGpxbmwgaGFyYWxkQG1lZXRz',
    //   'MGUyOWMwajI5Zmk2YmRrcThzZDlzMDlndTAgaGFyYWxkQG1lZXRz',
    //   'YnV0MjJhMmJrb2oybWEzYnJpZ3JlMTE4YTAgaGFyYWxkQG1lZXRz',
    //   'a3BvZjZnZTBuZm9zM3JvZGV0aTQzZ2FjNGsgaGFyYWxkQG1lZXRz',
    //   'NzBvbWNjajQ2MG82NmJiMjY0czM0YjlrNzRzajZiYjI2b3FtY2I5',
    //   'bWRxaTlpams2djJmaWEzaGQ4YW84a2hjYjggaGFyYWxkQG1lZXRz',
    //   'M3BhMjVqZmJqaHBybmMxMjR2Zmhhcm1vdnIgaGFyYWxkQG1lZXRz',
    //   'Njk5NHRyYTU5MnY1N2dybmdtYXVuN3JvNnEgaGFyYWxkQG1lZXRz',
    //   'NHNqaWF1cG91ZzcycG9sdXJoOHRsOHU0dDMgaGFyYWxkQG1lZXRz',
    //   'M2RtYjg5cjZyazZoZGF0ZDE4NWlqZmw3ZWEgaGFyYWxkQG1lZXRz',
    //   'MzVuNDQ5MW9ydWJycjQ4bDFpaXU1OHFpZTYgaGFyYWxkQG1lZXRz',
    //   'OXZuY2JzN2RybjA3bnBkMDc5aHVucXRhN2pfMjAyMzAzMTVUMDgwMDA
    // wWiBoYXJhbGRAbWVldHNoZXBoZXJkLmNvbQ',
    // ];

    // const stopwatch = new Stopwatch();
    // const result = await FirestoreMeetings.getMeetingsByDataEventIds(dataEventIds);
    // stopwatch.stop();
    // stopwatch.printDiff();

    // console.log('Result: ');
    // console.log(result);

    // const result = await SlackBoltAPI.listConversations();
    // console.log(result);
    // SlackBoltAPI.logMessage('test ');
  };

  const handleClick2 = async () => {
    toastInfo('Clicked', 'Button 2 clicked');

    // const docs =
    // const q = getDocs(query(collection(firestore, COLLECTIONS.MEETINGS), where(MEETING_PATH.googleData.ids.htmlLink, '==', 'MjZiODRwMjQzcHN1b2hqcTc1ajdrc24xODZfMjAyMzExMjBUMDcw')));
    // const q = getDocs(query(collection(firestore, COLLECTIONS.MEETINGS), where(MEETING_PATH.googleData.ids.htmlLink, '==', dataEventIds[2])));
    // const q = getDocs(query(collection(firestore, COLLECTIONS.MEETINGS), where('andrew.allen@dormapp.co', 'in', MEETING_PATH.googleData.attendees)));
    const sixWeeksAgoDate = SDate.getDateXWeeksAgo(6).toISOString();

    const userEmail = 'harald@meetshepherd.com';
    // const userEmail = 'andrew.allen@dormapp.co';

    // const attendeePath = new FieldPath('googleData', 'attendees');
    const calendarIdPath = new FieldPath('googleData', 'ids', 'calendarId');
    const q = query(collection(firestore, COLLECTIONS.MEETINGS),
      where(calendarIdPath, '==', userEmail),
      where(MEETING_PATH.date.start.date, '>=', sixWeeksAgoDate),
      orderBy(MEETING_PATH.date.start.date, 'desc'),
      limit(50));
    const docs = await getDocs(q);

    // l69V4FRvKJq44GZdOP37 - Meeting id of allen
    // const docs = await q;
    const data = docs.docs?.map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), userData.userId));
    // const most = data.map((meeting) => ({
    //   title: meeting.data.title, start: meeting.date.start.date, htmlLink: meeting.googleData.ids.htmlLink, meetingId: meeting.meetingId,
    // }));
    const most2 = data.map((meeting) => ({
      title: meeting.data.title, start: meeting.date.start.date, meetingId: meeting.meetingId, [meeting.meetingId]: meeting.meetingId,
    }));
    console.log('Docs', data);
    // eslint-disable-next-line no-restricted-syntax
    const i = 0;
    // eslint-disable-next-line no-restricted-syntax

    data?.forEach(async (meeting) => {
      const url = `https://app.meetshepherd.com/meeting/${meeting.meetingId}`;
      // Open new tab
      const analyticsData = await getAnalyticsDataByMeetingId(meeting.meetingId);
      console.log({ title: meeting.data.title, start: meeting.date.start.date, analyticsData });
    });

    // const customerId = 'cus_NpPsc8TJW3AQJI';
    // const customerId = StripeConstants.TEST.HARALD_CUSTOMER_ID;
    // // const { userId } = userData;
    // const result = await StripeAPI.createCheckoutSessionForAddingPaymentMethod(customerId);
    // const result = await StripeAPI.createTestSubscriptionWithPaidTrial(userId, customerId, true);
    // console.log('Result', result);

    // const subscriptionId = 'sub_1Mr0fmAwnRUlcRSmfVy8ZgmA';
    // const quantity = 5;
    // const subscriptionItemId = 'si_NcFAkthuBeIYfi';
    // const result = await StripeAPI.updateSubscriptionQuantity(
    //   subscriptionId, subscriptionItemId, quantity,
    // );

    // Call Intercom CF
    // IntercomCF.updateSubscriptionData(userData.userId, 'notSubscribed');
    // SlackBoltAPI.listConversations();

    // CEComs.sendBlacklistedDomains([domain]);
    // setDomain('');
    // TagsAPI.createNewTag(domain, userData.userId, userData.data.email);
    // GAPI.getMyCalendars();
  };
  const handleClick3 = async () => {
    toastInfo('Clicked', 'Button 3 clicked');

    const customerId = StripeConstants.TEST.HARALD_CUSTOMER_ID;
    const priceId = StripeConstants.TEST.PRICES.ONE_DOLLAR_FOR_TRIAL_EXTENSION;
    const result = await StripeAPI.createCheckoutSessionForSingleCharge(customerId, priceId);
    console.log(result?.url);

    // const data = await FirestoreMeetings
    // .getMeetingByDataEventId('NDZnbXVkM3Nwc3F1bmg2NDJyYnBwYTZuaTIgaGFyYWxkQG1lZXRz');
    // const customerId = 'cus_Mvypu11D06wAc6';
    // const customerId = 'cus_NpPsc8TJW3AQJI'; // Harald test customer with test clock
    // const checkoutSession = await StripeAPI.createTrialExtensionCheckoutSession(customerId);
    // if (!checkoutSession.id) {
    //   console.log('Error creating checkout session');
    //   return;
    // }
    // await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
    // Redirect to url

    // console.log(checkoutSession);
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
  };

  const handleClick5 = async () => {
    toastInfo('Clicked', 'Button 5 clicked');
    const eventId = '74fmcaponf9vtg8pa9ivr3md0n_20220207T130000Z';

    const time1 = new SDate();
    const allCalendards = await gapiGetMeeting(eventId, 'haraldlons@gmail.com', 'haraldlons@gmail.com');
    ConsoleImproved.log(allCalendards);
    SDate.printPassedSinceSDate(time1);
  };

  const handleClick6 = async () => {
    toastInfo('Clicked', 'Button 6 clicked');
    navigate('/');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  return (
    <Container>
      <TitleAtom>Harald Testing Page</TitleAtom>
      <TitleAtom>{count}</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} />
      <input onChange={handleInputChange} placeholder="Tag name" value={domain} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      {/* <ButtonSmall text="Click 4" onClick={handleClick4} />
      <ButtonSmall text="Click 5" onClick={handleClick5} />
      <ButtonSmall text="Click 6" onClick={handleClick6} /> */}
      <ReactJson src={json} />
    </Container>
  );
};

export default HaraldTestingPage;
