// functions.ts
import {
  doc,
  collection,
  getDoc,
  getDocs,
  Firestore,
} from 'firebase/firestore';

export const getMeetingIds = async (firestore: Firestore, userId: string) => {
  const userDocRef = doc(firestore, 'users', userId);
  const userMeetingsSnapshot = await getDocs(
    collection(userDocRef, 'user_meetings'),
  );
  return userMeetingsSnapshot.docs.map((document) => document.id);
};

export const getMeetingNotes = async (firestore: Firestore, userId: string) => {
  const userDocRef = doc(firestore, 'users', userId);
  const userMeetingsSnapshot = await getDocs(
    collection(userDocRef, 'user_meetings'),
  );

  const meetingsDetailsPromises = userMeetingsSnapshot.docs.map(
    async (document) => {
      const meetingData = document.data();
      const docId = `${document.id}_shared__Prosemirror`;
      const noteDocRef = doc(firestore, 'yjs_meetings', docId);
      const noteDocSnap = await getDoc(noteDocRef);

      if (noteDocSnap.exists() && noteDocSnap.data().text.trim()) {
        return {
          title: meetingData.data.title,
          date: meetingData.date.created.date,
          attendees: meetingData.googleData.attendees.join('; '),
          link: meetingData.googleData.conferenceData.link,
          text: noteDocSnap.data().text,
        };
      }
      return null;
    },
  );

  const meetingsDetails = await Promise.all(meetingsDetailsPromises);
  return meetingsDetails.filter((detail) => detail !== null);
};

export const convertNotesToCSV = (
  meetingsDetails: {
    title: string;
    date: string;
    attendees: string;
    link: string;
    text: string;
  }[],
) => {
  let csvContent = 'Title,Date,Attendees,Link,Text\n'; // CSV header
  meetingsDetails.forEach(({
    title, date, attendees, link, text,
  }) => {
    // Ensure fields containing commas or quotes are correctly encapsulated and escaped
    const row = `"${title}","${date}","${attendees}","${link}","${text.replace(
      /"/g,
      '""',
    )}"\n`;
    csvContent += row;
  });
  return encodeURI(`${csvContent}`);
};
